import { render, staticRenderFns } from "./PriceOneApp.vue?vue&type=template&id=05b004b0&scoped=true"
import script from "./PriceOneApp.vue?vue&type=script&lang=js"
export * from "./PriceOneApp.vue?vue&type=script&lang=js"
import style0 from "./PriceOneApp.vue?vue&type=style&index=0&id=05b004b0&prod&lang=css"
import style1 from "./PriceOneApp.vue?vue&type=style&index=1&id=05b004b0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05b004b0",
  null
  
)

export default component.exports